<template>
  <div class="card place-chooser">
    <div class="card-header">
      <div class="card-title h4">Selecione o local de atendimento</div>
    </div>
    <div class="card-body">
      <place-chooser-item v-for="place in places" :data="place" :key="place.id" @select="select">
      </place-chooser-item>
    </div>
  </div>
</template>

<script>
import PlaceChooserItem from './PlaceChooserItem.vue';

export default {
  emits: ['select-room'],
  components: {
    PlaceChooserItem,
  },
  data() {
    return {
      places: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$hospitalService.get('/place', {
        params: {
          offset: 0,
          limit: 50,
          hospital: localStorage.hospital,
        },
      })
        .then(({ data }) => {
          data.reduce((acc, place) => {
            place.rooms = place.rooms.filter((room) => !room.specialistRoom);
            return [...acc, place];
          }, []);
          this.places = data;
        })
        .catch(() => { });
    },
    select(place, room) {
      this.$emit('select-room', place, room);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

</style>
