<template>
  <div class="attendance-page page-container">
    <place-chooser @select-room="selectRoom" v-if="!place"></place-chooser>
    <div class="card" v-else>
      <div class="card-header">
        <div class="columns">
          <div class="column">
            <div class="card-title h4">{{ place.name }} - {{ room.name }}</div>
          </div>
          <div class="column text-right">
            <button
              class="btn btn-action btn-error btn-icon tooltip"
              @click="closeRoom"
              data-tooltip="Encerrar atendimento"
            >
              <fa-icon :icon="['fal', 'times']"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <p>Selecione os tipos de senha que você irá chamar ao chamar as próximas senhas.</p>
        <div class="dropdown">
          <button class="btn btn-primary dropdown-toggle" tabindex="0">
            Tipos de senhas<i class="icon icon-caret"></i>
          </button>
          <ul class="menu">
            <li v-for="(attendance, i) in place.attendance" v-bind:key="i">
              <label class="form-checkbox form-inline" v-bind:for="attendance.title">
                <input type="checkbox" v-bind:value="attendance.title" v-bind:id="attendance.title"
                  v-bind:name="attendance.title" v-model="ticketTypes">
                <i class="form-icon"></i> {{ attendance.title }}
              </label>
              <div v-bind:class="place.attendance.length - 1 !== i && 'divider'"></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body">
        <div class="password-control">
          <div v-if="noPassword" class="no-password-message">
            Não há senhas para serem chamadas neste momento
          </div>

          <div class="columns">
            <div class="column col-8 centered">
              <button class="btn btn-lg btn-primary btn-call-next btn-icon btn-icon-left" @click="callNext"
                v-bind:data-badge="totalPendingTicket" v-bind:class="totalPendingTicket && 'badge'"
                v-bind:disabled="isLoading">
                <fa-icon :icon="['fad', 'megaphone']"></fa-icon>
                Chamar próxima senha
              </button>
            </div>
            <div class="column col-4 centered">
              <div class="password text-center">
                <div class="password-label">SENHA</div>
                <div class="password-content">
                  <div v-if="hasPassword">
                    {{ password.value }}
                    <span v-if="password.isPreferential" class="text-small text-error tooltip"
                      data-tooltip="Atendimento preferencial">(P)</span>
                  </div>
                  <div v-else>--</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="history">
          <div class="h5 mb-2">Histórico de chamadas</div>
          <div class="loading loading-lg" v-if="history.loading"></div>
          <template v-else-if="topHistory.length > 0">
            <history-item :history="topHistory" @recall="recall"></history-item>
          </template>
          <template v-else>
            <div class="empty">
              <div class="empty-icon">
                <fa-icon :icon="['fad', 'megaphone']" size="2x"></fa-icon>
              </div>
              <p class="empty-title h5">Não há registros</p>
              <p class="empty-subtitle">Clique no botão abaixo para chamar a próxima senha.</p>
              <div class="empty-action">
                <button class="btn btn-primary" @click="callNext" v-bind:data-badge="totalPendingTicket"
                  v-bind:class="totalPendingTicket && 'badge'" v-bind:disabled="isLoading">Chamar senha</button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlaceChooser from './components/PlaceChooser.vue';
import HistoryItem from './HistoryItem.vue';

export default {
  components: {
    PlaceChooser,
    HistoryItem,
  },
  mounted() {
    this.load();
  },
  beforeUnmount() {
    this.stopRealtimeEvents();
  },
  data() {
    return {
      openSetting: true,
      place: null,
      counters: {
        types: {},
      },
      isLoading: false,
      ticketTypes: [],
      room: null,
      totalPendingTicket: 0,
      password: null,
      noPassword: false,
      history: {
        items: [],
        limit: 10,
        loading: false,
      },
    };
  },
  watch: {
    ticketTypes(currentValue, oldValue) {
      if (!currentValue.length) {
        this.ticketTypes = oldValue;
      }
      this.calculatePendingTickets();
      this.saveSelectedRoom({ place: this.place, room: this.room, ticketTypes: currentValue });
    },
  },
  computed: {
    hasPassword() {
      return this.password !== null;
    },
    topHistory() {
      return this.history.items.length > this.history.limit
        ? this.history.items.slice(0, this.history.limit)
        : this.history.items;
    },
  },
  methods: {
    load() {
      const savedRoom = this.getSelectedRoomSaved();
      if (savedRoom) {
        this.selectRoom(savedRoom.place, savedRoom.room);
        this.ticketTypes = savedRoom.ticketTypes;
      }
    },
    closeRoom() {
      delete localStorage.attendanceRoom;
      this.place = null;
      this.stopRealtimeEvents();
    },
    saveSelectedRoom({ place, room, ticketTypes }) {
      const attendanceRoom = {
        place,
        room,
        ticketTypes,
      };
      localStorage.setItem('attendanceRoom', JSON.stringify(attendanceRoom));
    },
    getSelectedRoomSaved() {
      if (localStorage.attendanceRoom) return JSON.parse(localStorage.attendanceRoom);

      return null;
    },
    selectRoom(place, room) {
      this.room = room;
      this.place = place;
      this.ticketTypes = place.attendance.map((attendance) => attendance.title);
      this.loadStats();
      this.loadHistory();
      this.startRealtimeEvents();
      this.saveSelectedRoom({ place, room, ticketTypes: this.ticketTypes });
    },
    calculatePendingTickets() {
      this.totalPendingTicket = this.ticketTypes.reduce((totalPending, type) => {
        totalPending += this.counters?.types[type]?.pending || 0;
        return totalPending;
      }, 0);
    },
    addHistoryItem(updatedTicket) {
      const ticketIndex = this.history.items.findIndex((ticket) => ticket._id === updatedTicket._id);

      if (ticketIndex !== -1) {
        this.history.items[ticketIndex] = updatedTicket;
        return;
      }
      this.history.items.unshift(updatedTicket);
    },
    callNext() {
      this.isLoading = true;
      const params = {
        place: this.place._id,
        roomId: this.room._id,
        roomName: this.room.name,
        type: this.ticketTypes.join(','),
      };

      this.$ticketService.get('/ticket/call', {
        params,
      })
        .then(({ data }) => {
          this.password = data.ticket;
        })
        .catch(() => {
          this.noPassword = true;
          setTimeout(() => {
            this.noPassword = false;
          }, 5000);
        }).finally(() => {
          this.isLoading = false;
        });
    },
    recall(ticket) {
      ticket.inPromise = true;
      const params = {
        id: ticket._id,
        roomId: this.room._id,
        roomName: this.room.name,
      };

      this.$ticketService.get('/ticket/call', { params }).then(({ data }) => {
        this.password = data.ticket;
      }).catch(() => {
      }).finally(() => {
        ticket.inPromise = true;
      });
    },
    realtimeListener({ name, data: ticket }) {
      this.counters.pending = ticket.totalPendingTicket;

      if (name === 'create-ticket') {
        this.counters.types[ticket.type].pending = this.counters.types[ticket.type]?.pending + 1 || 1;
        this.calculatePendingTickets();
        return;
      }

      const updatedTicket = {
        _id: ticket.ticketId,
        roomName: ticket.roomName,
        room: {
          name: ticket.roomName,
          id: ticket.roomId,
        },
        status: ticket.status,
        isPreferential: ticket.isPreferential,
        type: ticket.type,
        value: ticket.number,
        callsQuantity: ticket.callsQuantity,
        lastCall: Date.now(),
      };

      if (name === 'recall-ticket') {
        this.calculatePendingTickets();
        this.addHistoryItem(updatedTicket);
        return;
      }

      this.counters.types[ticket.type].pending -= 1;
      this.calculatePendingTickets();
      this.addHistoryItem(updatedTicket);
    },
    startRealtimeEvents() {
      return this.$realtime().then((service) => {
        const channel = service.channels.get(this.place._id);
        channel.subscribe(this.realtimeListener);
      });
    },
    stopRealtimeEvents() {
      return this.$realtime().then((service) => {
        const channel = service.channels.get(this.place._id);
        channel.unsubscribe(this.realtimeListener);
      });
    },
    loadHistory() {
      this.history.loading = true;

      const params = {
        place: this.place._id,
        hospital: this.place.hospital._id,
        status: 'called',
        limit: this.history.limit,
      };

      this.$ticketService.get('/ticket', { params })
        .then(({ data }) => {
          this.history.items = data;
        })
        .catch(() => {
        })
        .finally(() => {
          this.history.loading = false;
        });
    },
    loadStats() {
      this.$ticketService.get('/stats', {
        params: {
          place: this.place._id,
          hoursAgo: 36,
        },
      }).then(({ data }) => {
        this.counters = this.ticketTypes.reduce((acc, type) => {
          const counter = { pending: 0, total: 0, called: 0 };
          acc.types[type] = data[this.place._id]?.types[type] || counter;
          return acc;
        }, { types: {} });
        this.calculatePendingTickets();
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.attendance-page {
  .password-control {
    border-radius: $border-radius;
    background-color: $gray-color-ultralight;
    padding: $layout-spacing-lg;

    .no-password-message {
      background-color: $orange-color-400;
      border-radius: $border-radius;
      color: $primary-font-color;
      padding: $layout-spacing $layout-spacing-lg;
      margin-bottom: $layout-spacing-lg;
    }

    .btn-call-next {
      min-width: 15rem;
    }

    .password-label,
    .password-content {
      padding: 0 $layout-spacing-lg;
    }

    .password {
      background-color: #fff;
      border-radius: $border-radius;
    }

    .password-label {
      border-bottom: $border-width solid $border-color-light;
      color: $body-font-color;
      font-size: $font-size-sm;
      padding: $layout-spacing-sm;
    }

    .password-content {
      color: $primary-color;
      font-size: $font-size-xl;
      font-weight: 600;
    }
  }

  .history {
    margin-top: 1rem;

    .history-item {
      margin-bottom: $layout-spacing;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
