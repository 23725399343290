<template>
  <table class="table">
    <thead>
      <tr>
        <th>Senha</th>
        <th>Tipo</th>
        <th>Preferência</th>
        <th>Última chamada</th>
        <th>Chamadas</th>
        <th>Local</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(ticket, i) in history" :key="ticket._id">
        <td>{{ ticket.value }}</td>
        <td>{{ ticket.type }}</td>
        <td>{{ ticket.isPreferential ? 'Preferencial' : 'Normal' }}</td>
        <td>{{ new Intl.DateTimeFormat('pt-BR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          }).format(new Date(ticket.lastCall))
        }}</td>
        <td>{{ ticket.callsQuantity }}</td>
        <td>{{ ticket.roomName }}</td>
        <td class="text-right">
          <button class="btn btn-icon btn-icon-left"
            :class="[canCall(ticket) ? 'btn-gray-outline' : '', ticket.inPromise && 'loading']"
            :disabled="ticket.inPromise || canCall(ticket)" @click="recall(ticket, i)">
            <fa-icon :icon="['fad', 'megaphone']"></fa-icon>
            Chamar novamente
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  emits: ['recall'],
  props: {
    history: {
      type: Object,
    },
  },
  methods: {
    canCall(ticket) {
      return ['finished', 'canceled'].includes(ticket.status);
    },
    recall(ticket, index) {
      this.$emit('recall', ticket, index);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.history-item {
  border-radius: $border-radius;
  background-color: $gray-color-ultralight;

  .tile-icon svg {
    color: $gray-color;
  }

  .tile-action {
    .btn {
      background-color: transparent;
    }
  }
}
</style>
