<template>
  <st-tile :icon="['fad', 'user-headset']" :centered="true" size="lg" class="room-chooser-item">
    <div class="tile-title">{{ room.name }}</div>

    <template v-slot:action>
      <button class="btn btn-primary btn-icon btn-icon-left">
        <fa-icon :icon="['fad', 'check']"></fa-icon> Selecionar
      </button>
    </template>
  </st-tile>
</template>

<script>
export default {
  props: {
    room: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.room-chooser-item {
  border-radius: $border-radius;
  background-color: $gray-color-ultralight;
  margin-bottom: $layout-spacing-sm;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
