<template>
  <div class="accordion place-chooser-item" v-if="data.rooms.length > 0">
    <input type="checkbox" :id="`accordion-${data._id}`" name="accordion-checkbox" hidden checked>
    <label class="accordion-header" :for="`accordion-${data._id}`">
      <i class="icon icon-arrow-right mr-1"></i>
      <span class="accordion-title">{{ data.name }}</span>
    </label>
    <div class="accordion-body">
      <room-chooser-item v-for="room in data.rooms" :room="room" :key="room._id" @click="select(data, room)">
      </room-chooser-item>
    </div>
  </div>
</template>

<script>
import RoomChooserItem from './RoomChooserItem.vue';

export default {
  emits: ['select'],
  components: {
    RoomChooserItem,
  },
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    select(place, room) {
      this.$emit('select', place, room);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables';

.place-chooser-item {
  border-radius: $border-radius;
  background-color: $gray-color-ultralight;
  margin-bottom: $layout-spacing-sm;

  &:last-child {
    margin-bottom: 0;
  }

  .accordion-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $layout-spacing;
    cursor: pointer;
  }

  .accordion-title {
    display: inline;
    font-size: $font-size-lg;
    margin-left: $layout-spacing;
    font-weight: bold;
  }
}
</style>
